
/*============================================================================
  TYPOGRAPHY
==============================================================================*/


@font-face {
  font-family: 'CamptonLight';
  src: url('../fonts/Campton-Light.woff2') format('woff2'),
  url('../fonts/Campton-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


// Font Stacks
@mixin default-font($size: 17, $weight: 400, $lineHeight: 1.5em) {
  font-family: 'CamptonLight', Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
  @include optimize-font;
}

@mixin display-font() {
  font-family: 'CamptonLight', Helvetica, Arial, Verdana, sans-serif;
  font-weight: 400;
  @include optimize-font;
}

@mixin italic-font() {
  font-family: 'Kepler Std', Helvetica, Arial, Verdana, sans-serif;
  @include fs(17);
  font-weight: 400;
  font-style: italic;
  @include optimize-font;
}


// Body

p {
  line-height: 1.7em;
  margin-bottom: 1.7em;
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: none;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  &.tdu {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

a[href^=tel]{
  //text-decoration:none;
  &:hover, &:focus {
    //text-decoration: none;
  }
}

a:not([href]) {
  color: $black; text-decoration: none;
  &:hover, &:focus {
  }
}

.link:not(.image-card) {
  text-transform: lowercase;
  @include fs(15);
  letter-spacing: 0;
  padding: 0 0 15px;
  border-bottom: 1px solid $accentTransparent;
  display: inline-block;
  color: $accent;

  &:hover, &:focus {
    color: $accent;
  }
}

img {
  max-width: 100%; height: auto; display: inline-block; vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1.7em;
  margin: 0 0 0.6em;

  a {
    text-decoration: none;
    outline: 0; font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 35px);
  letter-spacing: 3px;
  padding-top: 10px;
  margin: 0 0 0.8em;
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 35px);
  letter-spacing: 3px;
  margin: 0 0 0.3em;
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 22px, 25px);
  letter-spacing: 0;
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }


// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0; border-bottom: none;
      }
    }
  }

  .rte & {
    list-style: none;
    margin: 0 0 60px;

    li {
      padding-left: 25px;
      position: relative;
      margin-bottom: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        width: 15px;
        height: 1px;
        background-color: $accent;
      }
    }
  }
}

ol {
  list-style: decimal;

  .rte & {
    //margin: 0 0 60px;

    li {
      padding-left: 9px;
    }
  }

}

.unstyled, nav ul {
  margin: 0 0 1.5em; padding: 0; list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none; list-style-image: none;
    padding: 0; background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block; height: 1px;
  border: 0; border-top: 1px solid $gray; margin: 50px 0;
}

blockquote {
  line-height: inherit;

  p {
    @include italic-font;
    @include fs(19);
    margin-bottom: 1.5em;
  }

  cite {
    @include fs(17);
    text-align: right;
    display: block;
  }
}
