
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle;
  letter-spacing: 2px;
  @include fluid-type(font-size, $mediumSize, $largeSize, 14px, 17px);
}


label, .label {
  display: block;
  padding: 10px 10px 10px 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], textarea, select {
  padding: 6px 10px;
  display: block;
  background: $grayLight;
  height: 45px;
  line-height: inherit;
  width: 100%;
  border: 1px solid rgba(116, 145, 154, 0.3);

  &:focus {
    outline: 0;
    border-color: $accent;
  }

  .contact & {
    background: $accentLight;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  width: 100%;
  height: 165px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box; width: auto; height: auto; padding: 0;
  margin: 3px 0; *margin-top: 0; *height: 13px; *width: 13px; line-height: normal; border: none;
}

input[type="file"] {
  background-color: #fff; padding: initial; border: initial; line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 11px 20px;
  margin: 10px 0 0;
  background-color: transparent;
  text-decoration: none;
  color: $accent;
  text-transform: lowercase;
  letter-spacing: 2px;
  @include fs(15);
  border: 1px solid #B6C5C9;
  min-width: 200px;
  text-align: left;
  position: relative;

  i {
    position: absolute;
    top: 16px;
    right: 15px;
    font-size: 8px;
    transition: $transitionDefault;
  }

  &:hover, &:focus, &:active {
    background-color: rgba(116, 145, 154, 0.1);
    box-shadow: none;

    i {
      transform: translateX(2px);
    }
  }

  &:active {
    box-shadow: none;
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
    //box-shadow: 0 0 1px 1px rgba(200,200,200,0.1);
  }
}

::placeholder { color: rgba(116, 145, 154, 0.6); }

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;
    margin-bottom: 0;

    li {
      list-style: none;
      background: none;
      list-style-image: none;
      margin: 0 0 15px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px;

      &.error {
        input,
        select {
          border-color: $error;
        }
      }
    }

    &.right-align {
      li {
        float: right;
      }
    }
  }

  input[type=submit],
  button[type=submit] {
    margin: 10px auto 0;
    float: none;
  }

  @media #{$medium} {
    ul {
      li {
        label, .label {
          width: 40%;
          float: left;

          .contact & {
            width: 100px;
          }

          :lang(fr) & {
            width: 120px;
          }
        }

        input,
        textarea,
        select,
        label + .choices {
          float: left;
          width: 60% !important;

          .contact & {
            width: calc(100% - 100px) !important;

            :lang(fr) & {
              width: calc(100% - 120px) !important;
            }
          }
        }
      }

      &.right-align {
        li {
          label, .label {
            //width: 20%;
            //margin-left: 20%;
          }
        }
      }
    }

    input[type=submit],
    button[type=submit] {
      float: right;
      margin: 10px 0 0;
    }
  }

  @media #{$huge} {
    ul {
      &.right-align {
        li {
          label, .label {
            width: 20%;
            margin-left: 20%;

            :lang(fr) & {
              width: 30%;
              margin-left: 10%;
            }
          }
        }
      }
    }
  }
}

.buttons {
  @include clearfix;
  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left; clear: none; margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge { display: none; }


.checkbox, .radio {
  position: relative;
}

// Checkbox & radio buttons
/* Base for label styling */
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked,
input[type="radio"]:not(:checked),
input[type="radio"]:checked {
  position: absolute; left: -9999px;
}

input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label,
input[type="radio"]:not(:checked) + label,
input[type="radio"]:checked + label {
  position: relative;
  padding-left: 30px;
  padding-top: 3px;
  margin-top: 25px;
  cursor: pointer;
  text-transform: none;
  color: $accent;
  display: inline-block;
  //letter-spacing: 0;
  width: 100%;
  float: right;

  &.italic {
    @include italic-font;
    @include fs(14);
    line-height: 1.4em;
  }

  @media #{$medium} {
    width: 60%;

    :lang(fr) & {
      width: calc(100% - 120px);
    }

    .contact & {
      width: calc(100% - 100px);
      margin-top: 0;

      :lang(fr) & {
        width: calc(100% - 120px);
      }
    }
  }

  @media #{$huge} {
    :lang(fr) & {
      width: 60% !important;
    }
  }
}

/* checkbox aspect */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(116, 145, 154, 0.3);;
  background-color: transparent;
  transition: $transitionDefault;
}
/* checked mark aspect */
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after {
  @extend .icon-font;
  content: "\e906";
  position: absolute;
  color: $accent;
  transition: $transitionDefault;
  top: 2px;
  left: 2px;
  font-size: 16px;
}
/* checked mark aspect changes */
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before,
input[type="radio"]:disabled:not(:checked) + label:before,
input[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: #ddd;
}
input[type="checkbox"]:disabled:checked + label:after,
input[type="radio"]:disabled:checked + label:after {
  color: #999;
}
input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label {
  color: #aaa;
}
/* accessibility */
input[type="checkbox"]:checked:focus + label:before,
input[type="checkbox"]:not(:checked):focus + label:before,
input[type="radio"]:checked:focus + label:before,
input[type="radio"]:not(:checked):focus + label:before {
  border-color: $accent;
}



// Select
select {
  border-radius: 0;
}



.label {
  margin-bottom: 0;
}

.input-toggle {
  width: 100%;
  float: left;
  @include clearfix;

  @media #{$medium} {
    width: 60%;
  }
}

.input-toggle__trigger {
  width: 75px;
  @include clearfix;
  float: left;

  input[type="checkbox"],
  input[type="radio"] {
    + .label {
      margin: 0;
      height: 45px;width: 75px;

      float: left;
      padding: 10px 10px 10px 30px;

      &:before {
        top: 12px;
      }

      &:after {
        top: 14px;
      }
    }
  }
}

.input-toggle__field {
  width: 100%;
  float: left;
  display: none;

  input {
    width: 100% !important;
  }

  @media #{$small} {
    width: calc(100% - 150px);
  }

  @media #{$large} {
    width: 100%;
  }

  @media #{$huge} {
    width: calc(100% - 150px);
  }
}
