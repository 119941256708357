/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/choices";
@import "plugins/fancybox";

// Components
@import "components/locations.scss";

  /* =============================================================================
   LAYOUT
   ========================================================================== */
  html {
  font-size: 62.5%;

  &.menu-open {
    overflow: hidden;

    body {
      overflow: hidden;
    }
  }
}

body {
  @include default-font;
  @include fluid-type(font-size, $mediumSize, $largeSize, 14px, 17px);
  color: $accent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 30px;

  @media #{$large} {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media #{$larger} {
    padding-left: 100px;
    padding-right: 100px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 24px;
  position: absolute;
  right: 0;
  height: 1px;
  border-top: 2px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 5px;
  }
  &:after {
    margin-top: 12px;
  }
}

@font-face {
  font-family: "diner-prive";
  src: url("../fonts/diner-prive.eot?a20mlo");
  src: url("../fonts/diner-prive.eot?a20mlo#iefix") format("embedded-opentype"),
    url("../fonts/diner-prive.ttf?a20mlo") format("truetype"),
    url("../fonts/diner-prive.woff?a20mlo") format("woff"),
    url("../fonts/diner-prive.svg?a20mlo#diner-prive") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
.icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "diner-prive" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e906";
}
.icon-chevron-down:before {
  content: "\e900";
}
.icon-instagram {
  position: relative;
  top: 2px;
  left: 2px;
}
.icon-instagram:before {
  content: "\e901";
}
.icon-facebook {
  position: relative;
  top: 1px;
}
.icon-facebook:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-quotation-mark-open:before {
  content: "\e904";
}
.icon-quotation-mark-close:before {
  content: "\e905";
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}
// .mb20.mb20 { margin-bottom: 20px; }
.mb40.mb40 {
  margin-bottom: 40px;
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  height: 100px;
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  transition: height $transitionDefault, box-shadow $transitionDefault;

  .container {
    @include clearfix;
  }

  &.small {
    height: 80px;
    box-shadow: 0px 0px 25px 0px rgba(116, 145, 154, 0.05);
  }

  @media #{$large} {
    height: 180px;

    &.small {
      height: 100px;
    }
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  display: inline-block;
  position: absolute;
  top: 26px;

  img {
    display: block;
    transition: $transitionDefault;
    width: 140px;

    .small & {
      width: 120px;
    }
  }

  .small & {
    top: 20px;
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }

  @media #{$medium} {
  }

  @media #{$large} {
    top: 60px;
  }

  @media #{$larger} {
    position: absolute;
    right: 50%;
    transform: translateX(50%);

    img {
      width: 230px;

      .small & {
        width: 180px;
      }
    }
  }
}

:lang(nl),
:lang(en) {
  .page-header__logo {
    @media #{$large} {
      position: absolute;
      right: 50%;
      transform: translateX(50%);

      img {
        width: 230px;
      }
    }
  }
}

.page-header__contact {
  background-color: $accent;
  color: $white;
  display: inline-block;
  padding: 7px 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  top: 0;
  right: 30px;
  transition: $transitionDefault;
  @include fs(13);

  &:hover,
  &:focus {
    color: $white;
    opacity: 0.8;
  }

  .small & {
    transform: translateY(-30px);
  }

  @media #{$medium} {
    @include fs(17);
    padding: 14px 20px;

    .small & {
      transform: translateY(-50px);
    }
  }

  @media #{$large} {
    right: 50px;
  }

  @media #{$larger} {
    right: 100px;
  }
}

.main-nav {
  text-transform: lowercase;
  color: $accent;
  transition: $transitionDefault;

  a {
    text-decoration: none;
    color: $accent;
    padding: 13px 0 14px;
    display: inline-block;
    border-bottom: 1px solid transparent;
  }

  a:hover,
  a:focus,
  li.active a {
    color: $accent;
    border-bottom: 1px solid $accentTransparent;
  }

  @media #{$medium} {
    margin-top: 55px;

    a {
      padding: 13px 0 7px;
      text-align: center;
    }

    .small & {
      margin-top: 24px;
    }

    li {
      display: inline-block;
      text-align: left;
      margin-right: 20px;
      // float: none; // SOFIE

      &:last-child {
        margin-right: 70px;
      }
    }
  }

  @media #{$large} {
    margin-top: 80px;

    .small & {
      margin-top: 30px;
    }

    a {
      padding: 13px 0 14px;
    }

    li {
      display: inline-block;
      float: left;
      text-align: left;

      &:nth-child(n + 4) {
        float: none;
        text-align: right;
      }

      &:last-child {
        margin-right: 70px;
      }
    }
  }

  @media #{$larger} {
    li {
      //width: 12%;
      float: left;
      margin-right: 40px;

      //&:nth-child(n+4) {
      &:nth-child(n + 3) {
        //width: calc((100% - 90px) * 0.08);
      }

      &:last-child {
        margin-right: 90px;
      }
    }
  }

  @media #{$huge} {
    li {
      //width: 14%;

      //&:nth-child(n+4) {
      &:nth-child(n + 3) {
        //width: calc((100% - 90px) * 0.10);
      }
    }
  }

  @media #{$massive} {
    li {
      //width: 15%;

      //&:nth-child(n+4) {
      &:nth-child(n + 3) {
        //width: calc((100% - 90px) * 0.12);
      }
    }
  }
}

:lang(fr) {
  .main-nav {
    @media #{$large} {
      li {
        float: none; // SOFIE
        &:nth-child(n + 2) {
          // float: none;  // SOFIE
          text-align: right;
        }
      }
    }
    @media #{$larger} {
      li {
        float: left;
        // margin-right: 20px;
        margin-right: 20px;
        // float: none; // SOFIE
        &:nth-child(n + 3) {
          float: none; // SOFIE
          text-align: right;
        }
        &:last-child {
          margin-right: 70px;
        }
      }
    }
  }
}

.main-nav__trigger {
  display: block;
  padding: 12px 35px 12px 15px;
  background-color: transparent;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 40px;
  height: 40px;
  right: 30px;
  color: $accent;
  text-transform: lowercase;
  @include fs(17);

  .small & {
    top: 17px;
  }

  .icon-hamburger {
    position: absolute;
    right: 0;
    top: 16px;

    .menu-open & {
      border-top-color: transparent;

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
        margin-top: 5px;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0;

  &:before,
  &:after {
    display: none;
  }

  .spacer {
    flex-grow: 1;
  }

  @media #{$maxMedium} {
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    text-align: center;
    padding: 50px 20px 20px;

    @include fs(19);

    .small & {
      top: 80px;
    }

    li {
      margin-bottom: 8px;
    }

    a {
      padding-bottom: 6px;
    }
  }

  @media #{$medium} {
    width: 100%;
    display: block;
    text-align: right;
  }
}

.language {
  position: absolute;
  top: 53px;
  right: 30px;
  transition: $transitionDefault;
  display: none;

  .small & {
    top: 21px;
  }

  @media #{$maxMedium} {
    .menu-open & {
      display: block;
      top: 100px;
      right: 50%;
      transform: translateX(50%);
    }
    .small & {
      top: 80px;
    }
  }

  @media #{$medium} {
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: -5px;
      height: 25px;
      width: 1px;
      background-color: $accentTransparent;
    }
  }

  @media #{$large} {
    right: 50px;
    top: 80px;

    .small & {
      top: 30px;
    }
  }

  @media #{$larger} {
    right: 100px;
  }
}

.language__trigger {
  height: 40px;
  @include fluid-type(font-size, $mediumSize, $largeSize, 10px, 13px);
  color: $accent;
  padding: 11px 15px 4px;
  text-transform: uppercase;
  background-color: white;

  &:before {
    @extend .icon-font;
    @extend .icon-chevron-down;
    font-size: 4px;
    position: absolute;
    top: 20px;
    right: 0;
    transition: $transitionDefault;

    .open & {
      transform: scale(-1);
    }
  }

  @media #{$maxMedium} {
    @include fs(15);
    padding: 5px 10px;
    height: 30px;
    display: inline-block;
    position: relative;
    background-color: transparent;

    &:before {
      display: none;
    }

    &:after {
      content: "";
      width: 20px;
      height: 1px;
      top: 100%;
      right: 50%;
      transform: translateX(50%);
      position: absolute;
      background-color: $accentTransparent;
    }
  }
}

.language__wrap {
  margin: 0;
  list-style: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: $accent;
  display: none;
  width: 100%;
  @include fluid-type(font-size, $mediumSize, $largeSize, 10px, 13px);
  text-transform: uppercase;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 0px rgba(116, 145, 154, 0.1);

  li {
    a {
      display: block;
      padding: 8px 15px 3px;
      color: $white;
    }

    &:first-child a {
      padding-top: 12px;
    }

    &:last-child a {
      padding-bottom: 7px;
    }
  }

  @media #{$maxMedium} {
    display: inline-block !important;
    position: relative;
    box-shadow: none;
    top: 0;
    width: auto;
    @include fs(15);
    background-color: transparent;

    li {
      display: inline-block;

      a {
        padding: 5px 10px;
        color: $accent;
      }
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  padding: 100px 0 0;

  @media #{$medium} {
    padding: 100px 0 170px;
  }

  @media #{$large} {
    padding: 180px 0 170px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  @include fs(15);
  padding: 40px 0;

  .grid {
    margin-bottom: 0;
  }

  a {
    color: $accent;

    &:hover,
    &:focus {
      color: $accentHover;
    }
  }

  @media #{$medium} {
    height: 170px;
    margin: -170px 0 0;
    z-index: 5;
  }
}

.page-footer__partners {
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;

  li {
    float: left;
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media #{$medium} {
    justify-content: left;
  }
}

.page-footer__section {
  list-style: none;
  position: relative;
  margin: 40px 0 0;
  text-align: center;

  li {
    padding: 5px 35px;
    line-height: 22px;
  }

  a + a {
    margin-left: 15px;
  }

  @media #{$medium} {
    float: right;
    margin: 0 0 0 -30px;
    text-align: left;

    li {
      float: left;
      width: 50%;
      padding: 5px 0 5px -30px;

      &:first-child {
        width: 100%;
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: 25%;
      }

      &:nth-child(6) {
        text-align: right;
        padding-right: 0;
      }
    }
  }

  @media #{$larger} {
    margin: 10px 0 0;
    text-align: right;

    li {
      padding: 0 20px;
      border-left: 1px solid $accentTransparent;
      width: auto;

      &:first-child {
        border-left: none;
        padding: 0;
        margin-bottom: 10px;

        + li {
          border-left: none;
          clear: both;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: auto;
      }
    }
  }

  @media #{$huge} {
    margin: 40px 0 0;

    li {
      padding: 0 10px;

      &:first-child {
        width: auto;
        padding: 0 10px 0 0;

        + li {
          border-left: 1px solid $accentTransparent;
          clear: none;
        }
      }
    }

    a + a {
      margin-left: 10px;
    }
  }

  @media #{$massive} {
    li {
      padding: 0 25px;

      &:first-child {
        padding: 0 25px 0 0;
      }
    }

    a + a {
      margin-left: 15px;
    }
  }
}

.page-footer__bottom-section {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  clear: both;
  @include fs(13);
  text-align: center;

  li {
    margin-bottom: 5px;
  }

  @media #{$medium} {
    text-align: left;
    margin: 7px 0 0 5px;
    float: left;

    li {
      display: inline-block;
      margin-bottom: 0;
      padding: 0 13px;
      position: relative;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      + li {
        &:before {
          position: absolute;
          content: "";
          height: 1px;
          width: 9px;
          background-color: $accent;
          opacity: 0.5;
          top: 8px;
          left: -6px;
        }
      }
    }
  }

  @media #{$larger} {
    margin: 20px 0 0;
    float: right;
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.4;
  transition-duration: 0.5s;
  display: inline-block;
  outline: 0;
  z-index: 9999;
  vertical-align: top;
  position: relative;
  top: 1px;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 15px;
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail {
}

.article-detail__image {
  margin-bottom: 1.5em;
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;

  ul,
  p {
    margin-bottom: 1em;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 2em -5px;
}

.social__item {
  padding: 0 0 5px 5px;
  float: left;
}

.social__link {
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: block;
  border-radius: 40px;
  background-color: $accent;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.social__link--facebook {
  background-color: #3c5b9b;
  &:hover,
  &:focus {
    background-color: darken(#3c5b9b, 8%);
  }
}

.social__link--twitter {
  background-color: #2daae1;
  &:hover,
  &:focus {
    background-color: darken(#2daae1, 8%);
  }
}

.social__link--pinterest {
  background-color: #cb2027;
  &:hover,
  &:focus {
    background-color: darken(#cb2027, 8%);
  }
}

.social__link--linkedin {
  background-color: #0173b2;
  &:hover,
  &:focus {
    background-color: darken(#0173b2, 8%);
  }
}

// Instagram feed
.social-title {
  text-align: center;
  @include italic-font;
  text-transform: lowercase;
  line-height: 1.4em;
  position: relative;
  margin-bottom: 60px;

  &:after {
    content: "";
    position: absolute;
    height: 30px;
    width: 1px;
    background-color: $accentTransparent;
    bottom: -40px;
    left: 50%;
  }
}

.social-grid {
}

.content-section {
  margin-bottom: 60px;
  position: relative;

  &[data-content-section-deco] {
    &:before,
    &:after {
      content: attr(data-content-section-deco);
      position: absolute;
      top: 30%;
      pointer-events: none;
      line-height: 0;
      @include fluid-type(font-size, $mediumSize, $largeSize, 13px, 15px);
      letter-spacing: 3px;
    }

    &:before {
      left: 0;
      transform: rotate(270deg) translateX(-50%) translateY(-15px);
      transform-origin: left;
    }

    &:after {
      right: 0;
      transform: rotate(90deg) translateX(50%) translateY(-15px);
      transform-origin: right;
    }

    @media #{$large} {
      &:before {
        transform: rotate(270deg) translateX(-50%) translateY(-25px);
        top: 50%;
      }

      &:after {
        transform: rotate(90deg) translateX(50%) translateY(-25px);
        top: 50%;
      }
    }

    @media #{$larger} {
      &:before {
        transform: rotate(270deg) translateX(-50%) translateY(-50px);
      }

      &:after {
        transform: rotate(90deg) translateX(50%) translateY(-50px);
      }
    }
  }

  @media #{$medium} {
    margin-bottom: 90px;
  }
}

.content-section--no-mb {
  .grid {
    .grid__item {
      &:last-child {
        .image-card {
          margin-bottom: 0;
        }
      }

      @media #{$medium} {
        .image-card {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Card
.card {
  background-color: $grayLight;
  position: relative;
  height: 100%;

  .link {
    text-align: center;
  }

  @media #{$large} {
  }

  @media #{$massive} {
    //height: 0;
    //padding-bottom: 100%;
  }
}

.card--dark {
  background-color: $accentLight;
}

.card--full {
  height: auto;
  padding-bottom: 0;

  .card__content {
    position: relative;
  }

  @media #{$massive} {
    &:not(.card--certificate) {
      .card__content {
        padding: 115px;
      }
    }
  }
}

.card--half {
  margin-bottom: 30px;

  @media #{$medium} {
    height: auto;
    padding-bottom: 0;
    min-height: 480px;
  }

  @media #{$massive} {
    padding-bottom: calc(50% - 15px);
  }
}

.card--big {
  background-color: $accentLight;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    text-align: center;
  }

  @media #{$large} {
    .card__content {
      padding: 40px;
      min-height: 750px;
    }
  }

  @media #{$larger} {
    .card__content {
      padding: 70px;
    }
  }

  @media #{$massive} {
    //height: 0;
    //padding-bottom: 100%;

    .card__content {
      padding: 95px;
    }
  }
}

.card--certificate {
  @media #{$medium} {
    .rte {
      column-count: 2;
      column-gap: 70px;
    }
  }

  @media #{$huge} {
    .rte {
      column-gap: 95px;
    }
  }
}

.card__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: $transitionDefault;

  img {
    display: none;
  }
}

.card__content {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media #{$small} {
    padding: 30px;
  }

  @media #{$medium} {
    padding: 70px;
  }

  @media #{$large} {
    min-height: 480px;
  }

  @media #{$massive} {
    //position: absolute;
    //top: 0;
    //left: 0;
    //height: 100%;
    //width: 100%;
  }
}

// Image card
.image-card {
  background-color: $grayLight;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;

  img {
    display: none;
  }

  &:hover,
  &:focus {
    .image-card__image {
      transform: scale(1.03);
    }

    .image-card__content {
      opacity: 0.95;
      color: $accent;
    }
  }
}

.image-card--large {
  padding-bottom: 100%;

  @media #{$small} {
    padding-bottom: 50%;
  }

  @media #{$large} {
    height: 100%;
    padding-bottom: 0;
  }
}

.image-card--medium {
  @extend .image-card--large;

  @media #{$medium} {
    height: 100%;
    padding-bottom: 0;
  }
}

.image-card--half {
  @media #{$medium} {
    padding-bottom: calc(50% - 15px);
  }
}

.image-card--no-animation {
  &:hover,
  &:focus {
    .image-card__image {
      transform: scale(1);
    }
  }
}

.image-card__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: $transitionDefault;

  img {
    display: none;
  }
}

.image-card__content {
  background-color: $accentLight;
  height: 75px;
  max-width: 388px;
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  transition: $transitionDefault;
  padding: 0 35px;

  @media #{$large} {
    height: 100px;
  }
}

.image-card__content--right {
}

.image-card__title {
  @include fluid-type(font-size, $smallSize, $mediumSize, 20px, 28px);
  @include fluid-type(font-size, $mediumSize, $massiveSize, 17px, 28px);
  text-align: center;
  margin: 0;
  line-height: 1.2em;

  .image-card__content--right & {
    text-align: right;
  }

  @media #{$medium} {
  }

  @media #{$large} {
    letter-spacing: 1px;
  }
  @media #{$massive} {
    letter-spacing: 2px;
  }
}

.image-card__type {
  text-align: right;
  margin: 0;
  letter-spacing: 2px;
}

.image-card__quote {
  position: absolute;
  background-color: #ebf2f4;
  opacity: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  line-height: 1.5;
  font-size: 1.9rem;
  transition: $transitionDefault;

  p {
    margin-top: 50px;
    transition: $transitionDefault;
  }

  &:hover {
    opacity: 0.9;

    p {
      margin-top: 0;
    }
    ~ .image-card__content {
      opacity: 0;
    }
  }
}

.partner-logos {
  ul {
    list-style: none;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0;
    text-align: center;

    li {
      float: left;

      + li {
        margin-left: 20px;
      }
    }
  }

  @media #{$small} {
    ul {
      li {
        + li {
          margin-left: 30px;
        }
      }
    }
  }

  @media #{$medium} {
    ul {
      li {
        + li {
          margin-left: 50px;
        }
      }
    }
  }

  @media #{$large} {
    .card__content {
      padding-right: 0;
    }
  }
}

.certificate-logos {
  @extend .partner-logos;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$medium} {
    ul {
      li {
        + li {
          margin-left: 30px;
        }
      }
    }
  }

  @media #{$large} {
    .card__content {
      padding-right: 70px;
    }

    ul {
      justify-content: center;
    }
  }

  @media #{$larger} {
    ul {
      li {
        + li {
          margin-left: 50px;
        }
      }
    }
  }

  @media #{$massive} {
    .card__content {
      padding-right: 100px;
    }

    ul {
      li {
        + li {
          margin-left: 80px;
        }
      }
    }
  }
}

// Testimonial card
.card--testimonial {
  margin-bottom: 30px;

  @media #{$medium} {
    height: 0;
    padding-bottom: calc(50% - 15px);
  }

  @media #{$huge} {
    height: 0;
    padding-bottom: 100%;
  }
}

.quote {
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{$medium} {
    padding: 0 70px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .card--half & {
      position: relative;
      padding: 30px 50px;
    }
  }

  @media #{$huge} {
    padding: 0 70px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .card--half & {
      position: absolute;
      padding: 0 70px;
    }
  }
}

.quote__content {
  position: relative;
  margin-bottom: 35px;
  padding-top: 35px;

  &:before,
  &:after {
    @extend .icon-font;
    position: absolute;
    opacity: 0.2;
    @include fluid-type(font-size, $mediumSize, $largerSize, 35px, 50px);
  }

  &:before {
    content: "\e904";
    top: 15px;
    left: -15px;
  }

  &:after {
    content: "\e905";
    bottom: -15px;
    right: -15px;
  }

  p {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media #{$medium} {
    margin-bottom: 70px;

    .card--half & {
      margin-bottom: 35px;
    }

    &:before {
      top: 0;
      left: -35px;
    }

    &:after {
      bottom: -50px;
      right: -35px;
    }
  }

  @media #{$huge} {
    .card--half & {
      margin-bottom: 70px;
    }
  }
}

// Gallery card
.card--gallery {
  margin-bottom: 30px;

  @media #{$huge} {
    height: 0;
    padding-bottom: 100%;

    .card__content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      min-height: 0;
    }
  }
}

// Filter
.filter {
  text-align: center;
  margin-bottom: 40px;
}

.filter__wrap {
  list-style: none;
  margin: 0;
  @include clearfix;
  display: inline-block;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media #{$medium} {
    li {
      float: left;
      margin-bottom: 0;

      + li {
        margin-left: 100px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: -80px;
          top: 12px;
          height: 1px;
          width: 60px;
          background-color: $accentTransparent;
        }
      }
    }
  }
}

.filter__trigger {
  color: $accent;
  background-color: transparent;
  @include fs(15);
  letter-spacing: 2px;
}

/* =============================================================================
   PAGES
   ========================================================================== */

/* Booking */
.booking-form {
  background-color: $grayLight;
  padding: 15px 15px 5px;

  .h1 {
    padding-top: 0;
  }

  @media #{$small} {
    padding: 30px 30px 15px;
  }

  @media #{$medium} {
    padding: 35px 35px 20px;
  }

  @media #{$large} {
    padding: 40px 40px 25px;
  }

  @media #{$huge} {
    padding: 65px 65px 50px;
  }
}

/* References */
.references {
  @media #{$huge} {
    blockquote {
      p {
        @include fs(16);
      }
      cite {
        @include fs(15);
      }
    }
  }

  @media #{$massive} {
    blockquote {
      p {
        @include fs(19);
      }
      cite {
        @include fs(17);
      }
    }
  }
}

/* Partners */
.partners {
  .image-card--medium {
    @media #{$medium} {
      //min-height: 480px;
      height: calc(100% - 30px);
    }
  }
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }
}
