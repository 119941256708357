.locations {
  .location__wrapper:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.location__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 0;
  transition: $transitionDefault;
  p {
    margin: 0;
  }

  @media #{$maxMedium} {
    flex-direction: column;
    align-items: flex-start;
  }
}

.location-info {
  display: flex;
  justify-content: center;
  align-items: center;

  //   @media #{$maxMedium} {
  //     flex-direction: column;
  //     padding-bottom: 10px;
  //   }
}

.location-label {
  //   transition: $transitionDefault;
  cursor: pointer;
  &:hover {
    background-color: red;
    color: white;

    .location-name::after {
      background-color: white;
    }
  }
}

.location-name {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    display: inline-block;
    position: relative;
    margin: 0 20px;
    content: "";
    height: 25px;
    width: 1px;
    background-color: $accentTransparent;

    // @media #{$maxMedium} {
    //   display: none;
    // }
  }
}

.map__wrapper {
  position: relative;
}

.map-search__wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 500px;
  bottom: $gutter;
  left: $gutter;
  padding: $gutter;
  min-width: 500px;
  background-color: $white;

  //   @media #{$maxLarge} {
  //     position: relative;
  //     width: 100%;
  //     min-width: auto;
  //     top: 0;
  //     left: 0;
  //     max-width: 100%;
  //   }
}

.location-select {
  margin-right: 10px !important;
}

.location-button {
  text-align: center;
}

.location-tooltip {
  margin-bottom: 0px !important;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
