// Validation engine
.formError { z-index: 990; }
.formError .formErrorContent { z-index: 991; }
.formError .formErrorArrow { z-index: 996; }

.formErrorInsideDialog.formError { z-index: 5000; }
.formErrorInsideDialog.formError .formErrorContent { z-index: 5001; }
.formErrorInsideDialog.formError .formErrorArrow { z-index: 5006; }

.inputContainer {
  position: relative; float: left;
}

.formError, .formError * {
  box-sizing: content-box;
}

.formError {
  position: absolute; top: 300px; left: 300px; display: block; cursor: pointer; margin: 0 0 0 -133px;

  .checkbox &, .radio & {
    margin: 0 0 0 !important;
    left: 0 !important;
    top: auto !important;
    bottom: calc(100% + 5px);

    @media #{$medium} {
      left: 40% !important;

      .contact & {
        left: 100px !important;
      }
    }
  }
}

.ajaxSubmit {
  padding: 20px; background: #55ea55; border: 1px solid #999; display: none;
}

.formError .formErrorContent {
  background: $error;
  position: relative;
  color: #fff;
  width: 160px;
  border: 1px solid $error;
  padding: 4px 10px 4px 10px;
  @include fs(14);
  box-shadow: 0 0 2px 1px rgba(0,0,0,0.1);
}

.formError .formErrorArrow {
  /*width: 15px; margin: -2px 0 0 13px;*/
  position:relative; margin: -2px 0 0 145px; height: 10px;
  display: none;

  &:after {
    content: ""; display: block;
    position: absolute; bottom: 0; left: 0;
    border: 8px solid transparent; border-bottom: 0; border-top-color: $error;
  }

  .checkbox &, .radio & {
    margin: -2px 0 0 12px;
  }
}

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px;
}

.formError .formErrorArrow div {
  display: none;
}
